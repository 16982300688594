import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { appendScript } from 'utils';

type CustomerIoMethod = (...args: (string | object)[]) => void;
type CustomerIoMethodArrayElement = string | object | string[] | object[];

// Create a hybrid interface that represents the array-like object with functions
interface CustomerIoMethodInterface extends Array<CustomerIoMethodArrayElement> {
  push: Array<CustomerIoMethodArrayElement>['push'];
  identify?: CustomerIoMethod;
  sidentify?: CustomerIoMethod;
  load?: CustomerIoMethod;
  track?: CustomerIoMethod;
  page?: CustomerIoMethod;
  on?: CustomerIoMethod;
  off?: CustomerIoMethod;
  [methodName: string]: CustomerIoMethod | undefined | unknown;
}

declare global {
  interface Window {
    _cio?: CustomerIoMethodInterface;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CustomerIoService {
  private document = inject(DOCUMENT);
  private platformId = inject(PLATFORM_ID);

  public init(customerIoSiteId: string) {
    if (isPlatformBrowser(this.platformId)) {
      appendScript(
        this.document,
        'https://assets.customer.io/assets/track.js',
        undefined,
        undefined,
        'head',
        'cio-tracker',
        {
          'data-site-id': customerIoSiteId,
          'data-use-array-params': 'true',
          'data-use-in-app': 'true',
        }
      );
      this.initializeCustomerIoMethods();
    }
  }

  private initializeCustomerIoMethods(): void {
    // Cast the empty array to our interface type
    window._cio = window._cio || ([] as unknown as CustomerIoMethodInterface);

    const createCustomerIoMethod = (f: string): CustomerIoMethod => {
      return (...args: (string | object)[]) => {
        window._cio?.push([f].concat(Array.prototype.slice.call(args, 0)));
      };
    };

    const methods = ['load', 'identify', 'sidentify', 'track', 'page', 'on', 'off'];
    for (const method of methods) {
      if (window._cio) {
        window._cio[method] = createCustomerIoMethod(method);
      }
    }
  }

  public identifyUser(id: string): void {
    if (window._cio && typeof window._cio.identify === 'function') {
      window._cio.identify({ id });
    } else {
      console.error('Customer.io identify method is not available.');
    }
  }
}
