import { Injectable } from '@angular/core';
import { LinkField } from '@prismicio/client';
import { ProductCatalogService, RequestParams } from '@yol-digital/ms-client';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Promotion } from 'interfaces';
import { PrismicSelfcareProducts, Product, ProductService } from 'product-shared';
import CatalogResponse = ProductCatalogService.CatalogResponse;
import PromotionResponse = ProductCatalogService.PromotionResponse;

@Injectable({
  providedIn: 'root',
})
export class SelfcareProductService extends ProductService {
  public getCatalog(query?: { code?: string }, params?: RequestParams) {
    const ziraEnabledProductClasses = this.featureFlagService.getFeatureValue('zira-enabled-product-classes', '');
    return ziraEnabledProductClasses?.length > 0
      ? this.api.public.getCatalog(
          {
            ...query,
            source: 'zira',
            productSpecClass: ziraEnabledProductClasses,
          },
          params
        )
      : this.api.public.getCatalog(query, params);
  }

  public getPromotionByCode(): Observable<{ promotions: PromotionResponse[] }> {
    throw new Error('Method not implemented.');
  }

  public getPCProducts$(): Observable<CatalogResponse[]> {
    return this.observableDataLoader.get('ProductCatalog', () =>
      this.getCatalog().pipe(map((res: { plans: ProductCatalogService.CatalogResponse[] }) => res.plans))
    );
  }

  public getPCProductByCode(): Observable<CatalogResponse> {
    throw new Error('Method not implemented.');
  }

  public buildCheckoutPath(
    product: Product,
    promotion?: Promotion,
    lineCheckId?: string
  ): { link: LinkField; queryParams?: object } {
    let queryParamsObj = this.buildCheckoutQueryParams(product, promotion, lineCheckId);
    queryParamsObj = Object.assign(queryParamsObj, this.featureFlagService.gbOverrideQueryParams);
    const queryParamsArray = Object.entries(queryParamsObj);
    const queryParams = queryParamsArray.join('&').replace(/,/g, '=');

    return {
      link: {
        url: `${this.config.checkoutUrl}/${this.languageService.current}/start/${product.product_code}${
          queryParamsArray.length > 0 ? `?${queryParams}` : ''
        }`,
        link_type: 'Web',
      },
    };
  }

  public customProductsFromPrismic(): Observable<{ productCode: string; promotionCode: string }[]> {
    return from(
      this.cmsService.getByType('selfcare_products', undefined, undefined, undefined, [
        'product_box.product_code',
        'promotion.rfe_promotion_code',
        'promotion.zira_promotion_code',
      ])
    ).pipe(
      map(res => {
        const products = res[0]?.data.default as PrismicSelfcareProducts[];
        return products?.map(item => {
          return {
            productCode: item.product.data.product_code,
            promotionCode: item.promotion.data?.rfe_promotion_code || item.promotion.data?.zira_promotion_code,
          };
        });
      })
    );
  }
}
