import { Environment } from './environment.model';

export const environment: Environment = {
  productionMode: true,
  googleTagManagerId: 'GTM-N2RNF95',
  prismicEndpoint: 'https://sunrise-yallo.cdn.prismic.io/api/v2',
  brand: 'yallo',
  fallbackLang: 'de',
  newMicroServiceEndpoint: 'https://ms.yallo.ch',
  websiteUrl: 'https://www.yallo.ch',
  allowedMsEnvs: [],
  eksMSEnvs: [],
  defaultUrlsEnvironment: '',
  posUrl: 'https://pos.yallo.ch',
  checkoutUrl: 'https://checkout.yallo.ch',
  featureFlagClientKey: 'sdk-1AyWzT53SObto3SV',
  sprinklerAppId: '6690eb6f187c274712e20ee8_app_220',
  customerIoSiteId: '2bbb4e3dfd5e7a8547e0',
};
